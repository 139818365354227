<template>
  <div id="app">
        <div v-if="menu" class="menu">
        <img class="close"  @click="closeMenu" src="#">
        <br />
        <br />
        <ul>
           <li><a @click="goTo('')">Accueil</a></li>
           <li><a @click="goTo('form')">Participez</a></li>           
           <li><a @click="goTo('reglement')">Règlement du jeu</a></li>
           <li><a @click="goTo('mentions')">Mentions légales</a></li>
           <li><a @click="closeMenu" href="https://www.ribambel.com/confidentialite" target="_blank">Vie Privée</a></li>
           <li><a @click="goTo('contact')">Contactez-nous</a></li>               
        </ul>
      </div>

        <headerElem></HeaderElem>
        <transition name="fade" mode="out-in">
        <router-view/>
        </transition>
        <FooterElem></FooterElem>
        <!-- <div class="menu-mobile"  @click="openMenu"><img   src="#"></div> -->
  </div>
</template>

<script>
import HeaderElem from '@/components/Header'
import FooterElem from '@/components/Footer'

export default {
  name: 'App',
    data: function () {
    return {
      menu: false
    }
  },  
  components: {
    HeaderElem,
    FooterElem,
  },
    methods : {
    openMenu() {
       this.menu = true
    },
    closeMenu() {
       this.menu = false
    },
    goTo(link) {
      this.menu = false
      this.$router.replace("/"+link);
    }
  }
}

</script>