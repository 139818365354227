<template>
  <main>

    <div class="status-data ">
 
            <p class="infos-done"><b>Nous avons bien reçu votre déclaration de facture.</b>
                <br><br>Elle sera traitée sous 8 jours, vous recevrez un mail dès qu'elle sera traitée.</p>

     <p class="infos-btn"> <router-link :to="link" tag="button" class="btn-done">RETOUR</router-link></p>


    </div>
  </main>
</template>

<script>
export default {
  name: "Done",
  data: function() {
    return {
        link : '/'
     }
  },
  mounted() {
      if(window.statuslink) this.link = window.statuslink
  }
}
</script>

<style scoped>
.infos-done {
    font-size: 16px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 100px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.infos-btn {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.btn-done {
    width: 200px !important;
    margin: 0 auto;
}

    .btn-done:hover:after {

      right: 50px;
    }
</style>
