<template>
  <footer>
    <cookie-law theme="blood-orange--rounded" storageName="cookies">
      <div class="cookie-banner" slot-scope="props">
        <div>
          Ce site web stocke des cookies sur votre ordinateur. Ces cookies sont
          utilisés pour collecter des informations sur la façon dont vous
          interagissez avec notre site Web et nous permettent de nous souvenir
          de vous. Nous utilisons ces informations afin d'améliorer et de
          personnaliser votre expérience de navigation et pour des analyses et
          des mesures sur nos visiteurs à la fois sur ce site Web et d'autres
          médias. Pour en savoir plus sur les cookies que nous utilisons,
          consultez notre
          <a
            :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${op_id}/politique.pdf`"
            target="_blank"
            >politique de confidentialité</a
          >. <br /><br />
          Si vous refusez, vos informations ne seront pas suivies lorsque vous
          visitez ce site Web. Un seul cookie sera utilisé dans votre navigateur
          pour mémoriser votre préférence de ne pas être suivi.
          <br />
          <br />
        </div>
        <div>
          <button class="skew" @click="props.accept">
            <span>J'accepte</span>
          </button>
          <button class="skew" @click="props.close">
            <span>Je refuse</span>
          </button>
        </div>
      </div>
    </cookie-law>

    <div class="head-content">
      <img class="tondeuse-footer" src="../assets/images/H310-1892.png" />
      <div><img src="../assets/images/hq-logo.png" /></div>
      <div class="footer-infos">
        <p>
          Grâce à plus de 325 années d'innovation et de passion, Husqvarna offre
          aux professionnels des produits spécialisés pour la forêt, les parcs
          et les jardins. Nous savons associer la convivialité et la sécurité
          pour vous permettre de réaliser vos travaux avec efficacité. Husqvarna
          propose une large gamme de produits et d'accessoires, des
          tronçonneuses, des découpeuses et des robots tondeuses.
          <span
            >Copyright - 2008-2021 Husqvarna AB (publ). Tous droits réservés.
             <a
              :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${op_id}/reglement.pdf`"
              target="_blank"
              >CGU</a
            >
            |
            <a
              :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${op_id}/politique.pdf`"
              target="_blank"
              >Politique de confidentialité</a
            ></span
          >
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
import CookieLaw from "vue-cookie-law";
let consts = require("../config");
export default {
  data: function() {
    return {
      op_id : consts.OP_ID,
    };
  },  
  components: { CookieLaw },
};
</script>