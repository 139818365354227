<template>
  <header :class="{ shadow: currentRouteName==='home' }">
    <div class="head-content">
      <i @click="toggle" class="fa-solid fa-bars mobile openmenu"></i>
      <Drawer class="mobile" @close="toggle" align="right" :closeable="true">
        <div class="mobile" v-if="open">
          <div class="menu-mobile">
            <p @click="toggle">
              <router-link to="/"
                ><img class="logo" src="../assets/images/hq-logo.png"
              /></router-link>
            </p>
            <p @click="toggle">
              <a href="https://www.husqvarna.com/fr/" target="_blank"
                ><i class="fa-solid fa-house"></i> &nbsp;SITE HUSQVARNA</a
              >
            </p>
            <p @click="toggle">
              <a href="https://contact.promo.dev/64c76fe9763f8dffbcbd9a7e" target="_blank"><i class="fa-solid fa-envelope"></i> &nbsp;CONTACTEZ
                NOUS</a
              >
            </p>
            <div>
              <ul class="sociaux">
                <li class="animate__animated animate__flipInY">
                  <a href="https://www.facebook.com/HusqvarnaFrance" target="_blank"
                    ><i class="fa-brands fa-facebook"></i
                  ></a>
                </li>
                <li class="animate__animated animate__flipInY">
                  <a href="https://www.instagram.com/husqvarnafranceofficiel/" target="_blank"
                    ><i class="fa-brands fa-instagram"></i
                  ></a>
                </li>
                <li class="animate__animated animate__flipInY">
                  <a href="https://www.linkedin.com/company/13048352/admin/" target="_blank"
                    ><i class="fa-brands fa-linkedin"></i
                  ></a>
                </li>
                <li class="animate__animated animate__flipInY">
                  <a href="https://www.youtube.com/user/HusqvarnaFrance" target="_blank"
                    ><i class="fa-brands fa-youtube"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Drawer>

      <div>
        <router-link to="/"
          ><img class="logo" src="../assets/images/hq-logo.png"
        /></router-link>
      </div>
      <div class="desktop">
        <ul>
          <li>
            <a href="https://www.husqvarna.com/fr/" target="_blank"
              ><i class="fa-solid fa-house"></i> &nbsp;SITE HUSQVARNA</a
            >
          </li>
          <li>
            <a href="https://contact.promo.dev/64c76fe9763f8dffbcbd9a7e" target="_blank"><i class="fa-solid fa-envelope"></i> &nbsp;CONTACTEZ
              NOUS</a
            >
          </li>
        </ul>
      </div>
      <div class="desktop">
        <ul class="sociaux">
          <li class="animate__animated animate__flipInY">
            <a href="https://www.facebook.com/HusqvarnaFrance" target="_blank"><i class="fa-brands fa-facebook"></i></a>
          </li>
          <li class="animate__animated animate__flipInY">
            <a href="https://www.instagram.com/husqvarnafranceofficiel/" target="_blank"
              ><i class="fa-brands fa-instagram"></i
            ></a>
          </li>
          <li class="animate__animated animate__flipInY">
            <a href="https://www.linkedin.com/company/13048352/admin/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
          </li>
          <li class="animate__animated animate__flipInY">
            <a href="https://www.youtube.com/user/HusqvarnaFrance" target="_blank"><i class="fa-brands fa-youtube"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import Drawer from "vue-simple-drawer";

export default {
  name: "HeaderElem",
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },  
  components: {
    Drawer,
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openRoute() {
      this.toggle();
    },
  },
};
</script>
<style>
.animate__animated.animate__flipInY {
  --animate-duration: 2s;
}
</style>
