<template>
  <main>
    <div v-if="popError" class="popup">
      <div class="popup_content animate__animated animate__bounceIn">
        <i @click="popError = false" class="fa-solid fa-xmark close_popup"></i>
        <h2>une erreur est survenue</h2>
        <p>
          Un problème à été rencontré.<br />
          Merci de contacter
          <a href="mailto:contact@promo.dev">contact@promo.dev</a> par email
        </p>
      </div>
    </div>

    <div v-if="askEmail" class="popup">
      <div class="popup_content animate__animated animate__bounceIn">
        <i @click="askEmail = false" class="fa-solid fa-xmark close_popup"></i>
        <h2>votre adresse email</h2>
        <p>
          Veuillez renseigner votre adresse mail, votre accès va vous être
          envoyé par mail.
        </p>
        <Form
          v-if="submission_id"
          id="form"
          :form_id="form_id"
          :uid="uid"
          :submission_id="submission_id"
          :server="server"
          v-on:results="getResult"
        />
      </div>
    </div>

    <div v-if="sendEmail" class="popup">
      <div class="popup_content animate__animated animate__bounceIn">
        <i @click="sendEmail = false" class="fa-solid fa-xmark close_popup"></i>
        <h2>ACCès à votre espace</h2>
        <p>
          Un lien vous permettant d’accéder à votre espace revendeur vient de
          vous être envoyé par email.<br /><br /><span>
            Si le message n'apparaît pas dans votre boîte de réception,
            recherchez-le dans vos autres dossiers. Si un filtre de courrier
            indésirable ou une règle de messagerie a déplacé l'e-mail, celui-ci
            peut se trouver dans le dossier Spam, Indésirable, Corbeille,
            Éléments supprimés ou Archives.</span
          >
        </p>
        <button @click="sendEmail = false">OK</button>
      </div>
    </div>
    <div class="home-login">
      <div class="login-form">
        <div class="big-title animate__animated animate__zoomIn">
          &nbsp;votre espace&nbsp;<br />revendeur&nbsp;
        </div>
        <form class="animate__animated animate__backInLeft">
          <h2>CONNECTEZ-VOUS</h2>
          <p>
            Merci de renseigner votre adresse email dans le champ ci-dessous.
          </p>
          <input
            v-model="email"
            @click="error = false"
            type="text"
            placeholder="Votre adresse email"
          />
          <button @click.prevent="checksubmission" v-if="!loading">
            CONNEXION
          </button>
          <button v-if="loading" disabled class="wait">
            PATIENTEZ&nbsp;<img src="../assets/images/wait.svg" />
          </button>
          <div
            v-if="error"
            class="error_login animate__animated animate__shakeX"
          >
            <i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Adresse email
            incorrecte
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
let consts = require("../config");

export default {
  name: "Home",
  data() {
    return {
      form_id: consts.FORM_ID,
      uid: undefined,
      submission_id: undefined,
      server: consts.SERVER_URL,
      loading: false,
      email: undefined,
      error: false,
      askEmail: false,
      sendEmail: false,
      popError: false,
    };
  },
  methods: {
    getResult(result) {
      console.log("result", result);
      if (result._id) {
        if (result.email) {
          this.sendLink();
          this.askEmail = false;
          this.sendEmail = true;
        }
      }
    },
    async sendLink() {
      this.loading = false;
      try {
        let { data } = await axios.get(
          consts.SERVER_URL +
            "/api/operations-custom/husqvarna-platform2023/sendlink/" +
            this.submission_id
        );
        if (data) {
          this.askEmail = false;
          this.sendEmail = true;
          this.loading = false;
        } else {
          this.openError();
        }
      } catch (e) {
        this.openError();
      }

      this.askEmail = false;
      this.loading = false;
      this.sendEmail = true;
    },
    closePopup() {
      this.error = true;
      this.loading = false;
    },
    openError() {
      this.askEmail = false;
      this.sendEmail = false;
      this.error = false;
      this.loading = false;
      this.popError = true;
    },
    async checksubmission() {
      this.loading = true;

      if (this.email) {
        try {
          let { data } = await axios.get(
            consts.SERVER_URL +
              "/api/operations-custom/husqvarna-platform2023/checkid/" +
              this.email
          );

          if (data._id) {
            this.submission_id = data._id;
            this.email = undefined;
            if (data.email) {
              this.sendLink();
            } else {
              this.askEmail = true;
              this.loading = false;
            }
          } else {
            this.closePopup();
          }
        } catch (e) {
          this.openError();
        }
      } else {
        this.closePopup();
      }
    },
  },
};
</script>
