import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

import * as Sentry from "@sentry/vue";


Sentry.init({
  Vue,
  dsn: "https://8b2451574342610aa68d53ea1e26922f@o482137.ingest.sentry.io/4506393581191168",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import VueDayjs from 'vue-dayjs-plugin'
Vue.use(VueDayjs)

import 'animate.css';
import "./main.scss";

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
