<template>
  <main>
      <div class="top-title">
            <h2><i class="fa-solid fa-envelope"></i> &nbsp;contactez nous</h2>
      </div>
      <div class="content">
          <div class="contact" v-html="contact" />
      </div>
  </main>
</template>
<script>
export default {
  name: "contact",
  data() {
    return {
      contact: window.contact,
    };
  },
};
</script>