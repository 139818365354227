import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Contact from "./views/Contact.vue";
import Done from "./views/Done.vue";
import axios from "axios";
import * as Sentry from "@sentry/vue";

var consts = require("./config");

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
    {
      path: "/regul/:_id/:_secu",
      name: "Regul",
      component: () => import("./views/Regul.vue"),
    },
    {
      path: "/done",
      name: "done",
      component: Done,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
  ],
});

export default router;

/*GET FORM DATA INFOS */
function getData() {
  try {
    return axios.get(consts.SERVER_URL + "/api/formdata/" + consts.OP_ID);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    /*GET FORM DATA INFOS */
    let { data } = await getData();

    if (data.mentionsLegales) window.mentions = data.mentionsLegales;
    if (data.contactezNous) window.contact = data.contactezNous;

    /* SET GOOGLE ANALYTICS */
    if (data.tracking) {
      if (to.name == undefined) {
        var name = "home";
      } else {
        var name = to.name;
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", data.tracking, {
        send_page_view: false,
      });
      gtag("event", "page_view", {
        page_title: name,
        page_location: window.location.href,
        page_path: to.path,
      });
    }

    /* CHECK ROUTES */
    var uri = window.location.href;
    var staging_heroku = uri.match(/heroku/g);
    var staging_netlify = uri.match(/netlify/g); // CHECK NETLIFY SD
    var local = uri.match(/localhost/g); // CHECK LOCALHOST

    if (local == null && staging_netlify == null && staging_heroku == null) {
      // Lucien.track("navigation", "page-view", {message: `#${to.name} ${to.path}`});
      console.log("PROD");

      /* FORCE HTTPS */
      if (location.protocol !== "https:") {
        window.location =
          "https://" +
          window.location.hostname +
          window.location.pathname +
          window.location.search;
      }

      /* CALCUL DE LA PERIODE */
      var dateBegin = new Date(data.date_debut);
      var dateOff = new Date(data.date_fin);
      var today = new Date();

      if (dateOff > today && dateBegin > today && to.path !== "/waiting") {
        // OP EN ATTENTE
        return next("/waiting");
      } else if (
        dateOff < today &&
        to.path !== "/finish" &&
        to.name !== "Status"
      ) {
        // OP TERMINEE
        return next("/finish");
      } else {
        // OP ENCOURS
        next();
      }
    } else {
      // URL DE DEV
      console.log("DEV");
      next();
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
});
